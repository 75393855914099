import React from "react";
import Hero from "./Hero";
import Content from "./Content";
import Block from "./Block";
import Text from "./Text";
import Galerie from "./Galerie";
import Imagegrid from "./Imagegrid";
import { Fade } from "react-reveal";
export default function Pagebuilder({ rows }) {
  const components = {
    Hero: Hero,
    Content: Content,
    Block: Block,
    Text: Text,
    Galerie: Galerie,
    Imagegrid: Imagegrid,
  };

  return (
    <div className="main">
      {rows &&
        rows.map((row, idx) => {
          const Row = components[row.__typename];
          if (Row) {
            return (
              <Fade key={`row-${idx}`}>
                <Row {...row} />
              </Fade>
            );
          }
        })}
    </div>
  );
}
