import React from "react";

export default function Imagegrid({ images }) {
  return (
    <div className="Imagegrid">
      <div className="inner">
        {images &&
          images.map((image, idx) => {
            return <img key={idx} src={image.url} alt="Image" />;
          })}
      </div>
    </div>
  );
}
