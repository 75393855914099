import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { useEffect } from "react";

export default function Galerie({ bilder }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [images, setImages] = useState([]);

  function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  const photos = bilder.map((bild) => {
    return {
      src: bild.url,
      width: 1,
      height: 1,
    };
  });

  const openLightbox = (event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };
  useEffect(() => {
    const imageArray = bilder.map((bild) => {
      return bild.url;
    });
    setImages(imageArray);
  }, [bilder]);

  return (
    <div className="Galerie">
      <Gallery photos={photos} onClick={openLightbox} />;
      {viewerIsOpen && (
        <Lightbox
          onImageLoad={() => {
            window.dispatchEvent(new Event("resize"));
          }}
          mainSrc={images[currentImage]}
          nextSrc={images[(currentImage + 1) % images.length]}
          prevSrc={images[(currentImage + images.length - 1) % images.length]}
          onCloseRequest={() => setViewerIsOpen(false)}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % images.length)
          }
        />
      )}
    </div>
  );
}
