import {
  IconBrandFacebook,
  IconBrandFacebookFilled,
  IconBrandInstagram,
} from "@tabler/icons-react";
import { Link, NavLink, useLocation } from "react-router-dom";
import React, { useState } from "react";

import Cross from "assets/cross.svg";
import LogoSmall from "assets/batschari-logo-header.svg";
import NavToggle from "assets/menu.svg";
import ShopButton from "assets/shopbutton.png";
import styles from "./header.module.scss";

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();

  const TopMenu = ({ label, children }) => {
    return (
      <div className="top-menu">
        <div className="button">{label}</div>
        <div className="children">{children}</div>
      </div>
    );
  };
  return (
    <div className={`${styles.Header} Header`}>
      <NavLink onClick={() => setShowMenu(false)} to="/">
        <img
          className="logo-small"
          src={LogoSmall}
          alt="Batschari Palais"
        ></img>
      </NavLink>
      <div className={`Nav ${showMenu ? "menu-visible" : "menu-hidden"}`}>
        <NavLink onClick={() => setShowMenu(false)} end to="/">
          Startseite
        </NavLink>
        <NavLink onClick={() => setShowMenu(false)} to="/suiten">
          Suiten
        </NavLink>
        <NavLink onClick={() => setShowMenu(false)} to="/galerie">
          Galerie
        </NavLink>
        <NavLink onClick={() => setShowMenu(false)} to="/angebote">
          Angebote
        </NavLink>
        <NavLink onClick={() => setShowMenu(false)} to="/wohnenaufzeit">
          Living
        </NavLink>
        <TopMenu label={"Hotel"}>
          <NavLink onClick={() => setShowMenu(false)} to="/hotel">
            Information
          </NavLink>
          <NavLink onClick={() => setShowMenu(false)} to="/anreise">
            Anreise
          </NavLink>
        </TopMenu>

        <a
          href="https://www.hab-academy.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wellness
        </a>
      </div>
      <div className="button-grp">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/batscharipalais8"
        >
          <IconBrandFacebook color="white" size={24} />
        </a>
        <a
          target="_blank"
          href="https://instagram.com/batscharipalais"
          rel="noopener noreferrer"
        >
          <IconBrandInstagram color="white" size={24} />
        </a>
        <a
          onClick={() => setShowMenu(false)}
          className="booklink"
          target="_blank"
          href="https://batscharipalais.direct-reservation.net/"
        >
          Jetzt buchen
        </a>
      </div>
      <button className="nav-toggle" onClick={() => setShowMenu(!showMenu)}>
        <img
          src={showMenu ? Cross : NavToggle}
          alt="Menü"
          className={"nav-toggle"}
        />
      </button>
    </div>
  );
}
