import "./assets/style.scss";
import "swiper/css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Page from "./components/Page";
import Layout from "./components/common/Layout";
import ScrollTop from "./components/common/ScrollTop";
import GTMTracker from "./components/common/GTMTracker";
import LanguageSwitch from "./components/common/LanguageSwitch";

function App() {
  return (
    <Router>
      <GTMTracker />
      <ScrollTop />
      <Layout>
        <Routes>
          <Route
            path="/kontakt"
            element={<Page id="clacod1ife3xy0buso4n33ekf" title={"Kontakt"} />}
          />
          <Route
            path="/hotel"
            element={<Page id="clacocghudz850austprup95u" title={"Hotel"} />}
          />
          <Route
            path="/suiten"
            element={<Page id="clacocthze4yp0buohtx1g4em" title={"Suiten"} />}
          />
          <Route
            path="/galerie"
            element={<Page id="clacoc230dshx0atfrag6waq5" title={"Galerie"} />}
          />
          <Route
            path="/angebote"
            element={<Page id="cleeh4znsue8b0bt877dhyjtc" title={"Angebote"} />}
          />
          <Route
            path="/wohnenaufzeit"
            element={
              <Page id="clfm9k4q6m0i50buptndhtf4x" title={"Batschari Living"} />
            }
          />
          <Route
            path="/impressum"
            element={
              <Page id="cl8be27c333p90buphnvnkph0" title={"Impressum"} />
            }
          />
          <Route
            path="/datenschutz"
            element={
              <Page id="cl8be3ilq37i50bupgpyqqs8t" title={"Datenschutz"} />
            }
          />
          <Route
            path="/anreise"
            element={<Page id="clacod1ife3xy0buso4n33ekf" title={"Anreise"} />}
          />

          <Route
            path="/"
            element={<Page id="cl8a6u1bm4yh80bupqlah2fxq" title={"Homepage"} />}
          />
          <Route path="/:lang/:url" element={<LanguageSwitch />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
