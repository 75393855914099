import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

export default function GTMTracker() {
  const location = useLocation();

  useEffect(() => {
    const data = {
      event: "pageview",
      page: {
        url: location.pathname,
        title: document.title,
      },
    };

    TagManager.dataLayer(data);
  }, [location]);
  return "";
}
