import React, { useEffect, useState } from "react";
import Pagebuilder from "./Pagebuilder";
import { getData } from "../functions";
import FooterNav from "./FooterNav";
import { useLocation } from "react-router-dom";

export default function Page({ id, title }) {
  const location = useLocation();
  const [content, setContent] = useState([]);
  useEffect(() => {
    const fetchContent = async () => {
      const page = await getData(id);
      setContent(page);
    };
    fetchContent();
  }, [id]);

  useEffect(() => {
    document.title = title + " Batschari Palais, Baden Baden";
  }, [location]);

  return (
    <div className="Homepage Page">
      <Pagebuilder rows={content.pagebuilder} />
    </div>
  );
}
