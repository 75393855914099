import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import OutsideClickHandler from "react-outside-click-handler";
import Persons from "assets/persons.svg";

export default function DatePicker() {
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [people, setPeople] = useState(2);
  function isMobile() {
    if (window.innerWidth < 768) return true;
    return false;
  }
  const [date, setDate] = useState([
    {
      startDate: today,
      endDate: tomorrow,
      key: "selection",
    },
  ]);
  const submit = () => {
    const websiteID = 1696;
    const accomodation = {
      EstablishmentIDs: [7494],
      DestinationCodes: [],
    };
    const passengerGroups = [
      {
        adults: parseInt(people),
        childrenAges: [],
      },
    ];
    date[0].startDate.setHours(15);
    date[0].endDate.setHours(15);
    const query = {
      DateFrom: date[0].startDate,
      DateTo: date[0].endDate,
      LanguageCode: "DE",
      CurrencyCode: "EUR",
      PassengerGroups: passengerGroups,
      Accommodation: accomodation,
    };
    console.log(query);
    const module = ["Accommodation"];
    var returnURL =
      "http://api.direct-reservation.net/" +
      "?websiteID=" +
      websiteID +
      "&query=" +
      JSON.stringify(query) +
      "&modules=" +
      JSON.stringify(module);
    window.open(returnURL);
  };
  const roomarray = [1, 2, 3, 4];
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setPickerOpen(false);
      }}
    >
      <div className="datepicker">
        <div className="row">
          <div className="inner">
            <div className="grp">
              <div>
                <label>Anreise</label>
              </div>
              <input
                onClick={() => setPickerOpen(true)}
                type="text"
                onChange={(e) => e.preventDefault()}
                value={date[0].startDate.toLocaleDateString("de-DE")}
              />
            </div>
            <div className="grp">
              <div>
                <label>Abreise</label>
              </div>
              <input
                onClick={() => setPickerOpen(true)}
                type="text"
                onChange={(e) => e.preventDefault()}
                value={date[0].endDate.toLocaleDateString("de-DE")}
              />
            </div>
            <div className="grp select">
              <select
                value={people}
                onChange={(e) => setPeople(e.target.value)}
              >
                {roomarray.map((key, index) => {
                  return (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  );
                })}
              </select>
              <img src={Persons} alt="Anzahl Personen" />
            </div>
            <div className="grp-button">
              <button onClick={() => submit()} type="button">
                Zimmer finden
              </button>
            </div>
          </div>

          {pickerOpen && (
            <div className="picker">
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setDate([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={date}
                minDate={new Date()}
                locale={locales["de"]}
                months={isMobile() ? 1 : 2}
                direction="horizontal"
                displayMode="dateRange"
                showDateDisplay={false}
              />
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
}
