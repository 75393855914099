import React from "react";
import Slider from "react-slick";

export default function Block({
  bild,
  headline,
  text,
  ausrichtung,
  link,
  slides,
}) {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    fade: true,
    arrows: true,
    dots: true,
  };
  return (
    <section className={`Block align-${ausrichtung}`}>
      <div className="content">
        <div className="inner">
          <h2>{headline}</h2>
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: text?.html }}
          ></div>
          {link && (
            <a
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              href={link}
            >
              Jetzt buchen{" "}
            </a>
          )}
        </div>
      </div>
      <div className="bild" style={{ position: "relative" }}>
        {slides?.length > 0 ? (
          <Slider
            {...settings}
            style={{ height: "100%", position: "absolute", width: "100%" }}
          >
            {slides &&
              slides.map((slide, idx) => {
                return (
                  <img
                    className="batschari-slide"
                    src={slide.url}
                    alt="slide"
                    key={`slide-${idx}`}
                  />
                );
              })}
          </Slider>
        ) : (
          <img src={bild?.url} />
        )}
      </div>
    </section>
  );
}
