import React from "react";
import { Link } from "react-router-dom";

export default function Content({ content, headline }) {
  return (
    <div className="content">
      <div className="wrap">
        <h1>{headline}</h1>
        <div dangerouslySetInnerHTML={{ __html: content.html }}></div>
      </div>
    </div>
  );
}
