import { request } from "graphql-request";

async function getData(id) {
  const { page } = await request(
    "https://api-eu-central-1.hygraph.com/v2/ckoifd1ie5nvh01xo3bfhh2cb/master",
    `
      {
        page(where: {id: "${id}"}) {
          pagebuilder {
            ... on Content {
              __typename
              id
              headline
              content {
                html
              }
              hintergrund {
                hex
              }
            }
           
            ... on Hero {
              __typename
              id
              headline
              subline
              background {
                url
              }
              logo {
                url
              }
              slides {
                url
              }
              contact {
                html
              }
            }
            ... on Block {
              __typename
              id
              headline
              bild {
                url
              }
              ausrichtung 
              text {
                html
              }
              link
              slides {
                url
              }
            }
            ... on Text {
              __typename
              id
              headline
              hintergrundfarbe {
                hex
              }
              text {
                html
              }
            }
            ... on Galerie {
              __typename
              id
              bilder {
                url
              }
            }
            ... on Imagegrid {
              __typename
              id
              images {
                url
              }
            }
          }
          navigations {
            id
            title
            slug
            page {
              ... on Page {
                id
                title
                slug
              }
            }
          }
        }
      }
    `
  );
  return page;
}

export { getData };
