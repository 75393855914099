import DatePicker from "../DatePicker";
import { Link } from "react-router-dom";
import React from "react";

export default function Footer({ data }) {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: `<div id="libertas-footer" style="background:#111"><div style="background:#222;padding:20px;padding-top:40px;display:flex;justify-content:center"><a href="https://www.libertashotels.com" target="_blank" rel="noopener noreferrer"><img src="https://libertas-api.12-05.com/wp-content/uploads/2024/07/footer_logo.svg" alt="Logo"></a></div><div style="height:40px;border-left:50% solid transparent;border-right:50vw solid transparent;border-top:20px solid #222"></div><div style="background:#111"><div style="padding:32px;display:grid;grid-template-columns:repeat(auto-fill, minmax(280px, 1fr));gap:16px;margin:0 auto;box-sizing:border-box;max-width:1200px"><a target="_blank" href="https://hotel-germania.at/" style="width:100%;box-sizing:border-box;padding:16px;display:flex;justify-content:flex-end;flex-direction:column;height:240px;border-radius:4px;overflow:hidden;position:relative;text-decoration:none"><div style="position:absolute;height:100%;width:100%;top:0;left:0;z-index:2;background:rgba(0, 0, 0, 0)
      linear-gradient(
        360deg,
        #1c1c1c 0%,
        rgba(28, 28, 28, 0.4) 45%,
        rgba(28, 28, 28, 0.1) 100%
      )
      0% 0% no-repeat padding-box"></div><img style="position:absolute;height:100%;width:100%;top:0;object-fit:cover;left:0;transition:all 0.5s ease;z-index:1" src="https://libertas-api.12-05.com/wp-content/uploads/2024/07/bregenz.png" alt="Bregenz"><div style="position:relative;z-index:10"><div><div style="font-size:28px;text-transform:uppercase;font-weight:bold;color:white">Bregenz</div><div style="font-size:16px;color:#B49062">Germania</div></div></div></a><a target="_blank" href="https://www.weinromantikhotel.com/kulinarik/reservierung" style="width:100%;box-sizing:border-box;padding:16px;display:flex;justify-content:flex-end;flex-direction:column;height:240px;border-radius:4px;overflow:hidden;position:relative;text-decoration:none"><div style="position:absolute;height:100%;width:100%;top:0;left:0;z-index:2;background:rgba(0, 0, 0, 0)
      linear-gradient(
        360deg,
        #1c1c1c 0%,
        rgba(28, 28, 28, 0.4) 45%,
        rgba(28, 28, 28, 0.1) 100%
      )
      0% 0% no-repeat padding-box"></div><img style="position:absolute;height:100%;width:100%;top:0;object-fit:cover;left:0;transition:all 0.5s ease;z-index:1" src="https://libertas-api.12-05.com/wp-content/uploads/2024/07/muelheim.png" alt="Mülheim/Mosel"><div style="position:relative;z-index:10"><div><div style="font-size:28px;text-transform:uppercase;font-weight:bold;color:white">Mülheim/Mosel</div><div style="font-size:16px;color:#B49062">Richtershof</div></div></div></a><a target="_blank" href="https://latini.libertashotels.com" style="width:100%;box-sizing:border-box;padding:16px;display:flex;justify-content:flex-end;flex-direction:column;height:240px;border-radius:4px;overflow:hidden;position:relative;text-decoration:none"><div style="position:absolute;height:100%;width:100%;top:0;left:0;z-index:2;background:rgba(0, 0, 0, 0)
      linear-gradient(
        360deg,
        #1c1c1c 0%,
        rgba(28, 28, 28, 0.4) 45%,
        rgba(28, 28, 28, 0.1) 100%
      )
      0% 0% no-repeat padding-box"></div><img style="position:absolute;height:100%;width:100%;top:0;object-fit:cover;left:0;transition:all 0.5s ease;z-index:1" src="https://libertas-api.12-05.com/wp-content/uploads/2024/06/storage.googleapis.com_bumorca-bum-prod_bum-cms_Geo_Europa_Oesterreich_europa-oesterreich-zell-am-see-alpen-berge-zellersee-sommer-scaled.webp" alt="Zell am See"><div style="position:relative;z-index:10"><div><div style="font-size:28px;text-transform:uppercase;font-weight:bold;color:white">Zell am See</div><div style="font-size:16px;color:#B49062">Latini</div></div></div></a><a target="_blank" href="https://www.rebstock-kehl.de" style="width:100%;box-sizing:border-box;padding:16px;display:flex;justify-content:flex-end;flex-direction:column;height:240px;border-radius:4px;overflow:hidden;position:relative;text-decoration:none"><div style="position:absolute;height:100%;width:100%;top:0;left:0;z-index:2;background:rgba(0, 0, 0, 0)
      linear-gradient(
        360deg,
        #1c1c1c 0%,
        rgba(28, 28, 28, 0.4) 45%,
        rgba(28, 28, 28, 0.1) 100%
      )
      0% 0% no-repeat padding-box"></div><img style="position:absolute;height:100%;width:100%;top:0;object-fit:cover;left:0;transition:all 0.5s ease;z-index:1" src="https://libertas-api.12-05.com/wp-content/uploads/2024/07/kehl.png" alt="Kehl/Strassburg"><div style="position:relative;z-index:10"><div><div style="font-size:28px;text-transform:uppercase;font-weight:bold;color:white">Kehl/Strassburg</div><div style="font-size:16px;color:#B49062">Rebstock</div></div></div></a><a target="_blank" href="https://schuetthof.libertashotels.com/" style="width:100%;box-sizing:border-box;padding:16px;display:flex;justify-content:flex-end;flex-direction:column;height:240px;border-radius:4px;overflow:hidden;position:relative;text-decoration:none"><div style="position:absolute;height:100%;width:100%;top:0;left:0;z-index:2;background:rgba(0, 0, 0, 0)
      linear-gradient(
        360deg,
        #1c1c1c 0%,
        rgba(28, 28, 28, 0.4) 45%,
        rgba(28, 28, 28, 0.1) 100%
      )
      0% 0% no-repeat padding-box"></div><img style="position:absolute;height:100%;width:100%;top:0;object-fit:cover;left:0;transition:all 0.5s ease;z-index:1" src="https://libertas-api.12-05.com/wp-content/uploads/2024/07/zell1.png" alt="Zell Am See"><div style="position:relative;z-index:10"><div><div style="font-size:28px;text-transform:uppercase;font-weight:bold;color:white">Zell Am See</div><div style="font-size:16px;color:#B49062">Schütthof</div></div></div></a><a target="_blank" href="https://www.theaterhotel-wien.at" style="width:100%;box-sizing:border-box;padding:16px;display:flex;justify-content:flex-end;flex-direction:column;height:240px;border-radius:4px;overflow:hidden;position:relative;text-decoration:none"><div style="position:absolute;height:100%;width:100%;top:0;left:0;z-index:2;background:rgba(0, 0, 0, 0)
      linear-gradient(
        360deg,
        #1c1c1c 0%,
        rgba(28, 28, 28, 0.4) 45%,
        rgba(28, 28, 28, 0.1) 100%
      )
      0% 0% no-repeat padding-box"></div><img style="position:absolute;height:100%;width:100%;top:0;object-fit:cover;left:0;transition:all 0.5s ease;z-index:1" src="https://libertas-api.12-05.com/wp-content/uploads/2024/07/wien.png" alt="Wien"><div style="position:relative;z-index:10"><div><div style="font-size:28px;text-transform:uppercase;font-weight:bold;color:white">Wien</div><div style="font-size:16px;color:#B49062">Theaterhotel</div></div></div></a></div></div><style>.footer{display:none!important;} header{display:none!important;}</style></div>`,
        }}
      />
      <div id="Footer">
        <ul>
          <li>
            <Link to="/">Startseite</Link>
          </li>

          <li>
            <Link to="/impressum">Impressum</Link>
          </li>
          <li>
            <Link to="/datenschutz">Datenschutz</Link>
          </li>
        </ul>
      </div>
      <DatePicker />
    </>
  );
}
