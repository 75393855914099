import React from "react";

export default function Text({ headline, text, hintergrundfarbe }) {
  return (
    <div className="Text">
      <div className="inner">
        <h2>{headline}</h2>
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: text.html }}
        ></div>
      </div>
    </div>
  );
}
