import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./hero.module.scss";
import Gutschein from "assets/gutschein.svg";
import Bewertung from "assets/trustyou.svg";
import Leave from "assets/leave.svg";

export default function Hero({ headline, logo, subline, contact, slides }) {
  const [isScrolled, setIcrolled] = useState(false);

  console.log(slides);
  // check if window is scrolled and set state isScrolled to true
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 500) {
      setIcrolled(true);
    } else {
      setIcrolled(false);
    }
  };

  // add the event listener
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <div className={styles.Hero}>
      {!isScrolled && (
        <style
          dangerouslySetInnerHTML={{
            __html: `.Header {background:transparent!important;}`,
          }}
        ></style>
      )}
      <div className="white-box">
        <div className={styles.subline}>{headline}</div>
        <div
          className={styles.headline}
          dangerouslySetInnerHTML={{ __html: contact.html }}
        ></div>
      </div>
      <div className="batschari-slider">
        <video autoPlay muted loop playsInline className={styles.video}>
          <source src={slides[0]?.url} type="video/mp4" />
        </video>
      </div>
      <div className={styles.sidebar}>
        <a
          href="https://shop.batschari-palais.de/de/shop?_gl=1*1b1t5sy*_gcl_au*MTQ3NzkyMDU5NC4xNjg3ODY5MzE1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Gutschein} alt="Test" />
          <span>Gutscheine</span>
        </a>
        <a
          href="https://www.trustyou.com/about/meta-review?ty_id=6de3410b-a898-4fe2-b2e8-483d4bbdae3b&lang=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Bewertung} alt="Test" />
          <span>Bewertung</span>
        </a>
        <a
          href="https://www.greensign.de/hotels/?bname=Batschari&country=&level="
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Leave} alt="Test" />
          <span>
            Greensign
            <br />
            Level 3
          </span>
        </a>
      </div>
    </div>
  );
}
