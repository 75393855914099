import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

export default function LanguageSwitch() {
  const { lang, url } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    window.Weglot.switchTo(lang);
    setTimeout(function () {
      navigate("/" + url);
    }, 300);
  }, [lang, url]);
  return <div>LanguageSwitch</div>;
}
